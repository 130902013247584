// TODO: use AWS SDK instead

const profile = {
    userPoolDomain: 'lisamanager', // Replace with your User Pool ID
    clientId: '5f910e49klcnvji6tavktthnc3', // Replace with your User Pool Client ID
    region: 'eu-west-2', // Replace with your AWS region
    redirectUri: 'https://lisamanager.com'
};

export class Cognito {
    jwt: string;

    public constructor() {
        this.jwt = '';
    }

    public token(): string {
        if (!this.jwt)
            throw new Error('Trying to fetch non-existent Cognito token');
        return this.jwt;
    }

    public setToken(token: string) {
        this.jwt = token;
    }

    // Function to construct Hosted UI URLs
    public url(action: string): string {
        const baseUrl = `https://${profile.userPoolDomain}.auth.${profile.region}.amazoncognito.com`;
        const url = `${baseUrl}/${action}?client_id=${profile.clientId}&response_type=code&redirect_uri=${profile.redirectUri}`;
        switch (action) {
            case 'login':
            case 'signup':
                return `${url}&scope=email+openid`;
            case 'logout':
                return `${url}&logout_uri=${profile.redirectUri}`;
            default:
                throw new Error('Invalid action for Hosted UI URL');
        }
    }

    public async convertToToken(code: string) {
        try {
            const response = await fetch(
                `https://${profile.userPoolDomain}.auth.${profile.region}.amazoncognito.com/oauth2/token`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: `grant_type=authorization_code&client_id=${profile.clientId}&code=${code}&redirect_uri=${decodeURIComponent(profile.redirectUri)}`
                }
            );

            const data = await response.json();

            if (data.id_token) {
                console.log('JWT Token:', data.id_token);
                this.jwt = data.id_token;
            } else {
                console.error('Error retrieving token:', data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
}