// Import a local module (can also import npm packages if needed)
import { App } from './app'
import { Cognito } from './cognito'
import { Api } from './api'



const cognito = new Cognito();
const api = new Api();
const app = new App(api, document.getElementById('grid'));

console.log("app loaded");

// Event listener for buttons with class 'app-button'
document.addEventListener('click', (event) => {
    // console.log(event.target);
    if (event.target.classList.contains('app-button')) {
        const action = event.target.id;
        if (['login', 'signup', 'logout'].includes(action)) {
            window.location.href = cognito.url(action);
        }
        else if (action === 'authenticate') {
            console.log("Authenticate");
            window.location.href = app.hmrcUrl(cognito.token());
        }
        else if (action === 'test-api') {
            api.getApi(cognito.token());
        }
        else
            app.event(action);
    }
});

// Function to handle JWT retrieval after successful login
window.addEventListener('load', () => {
    const handleLoad = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');  // from Cognito OR HMRC
        const state = urlParams.get('state');  // HMRC

        if (code) {
            if (state) {
                console.log(code, state);
                cognito.setToken(state);
                api.authorize(state, code);
            }
            else {
                const token = await cognito.convertToToken(code);
            }

            const elements = document.querySelectorAll('.app-button');
            elements.forEach(element => {
                element.classList.toggle('hidden');
            });
            document.getElementById('forms').classList.toggle('hidden');
            document.getElementById('intro').classList.toggle('hidden');
            await app.initialize(cognito.token());
        }

    };

    handleLoad();
});


