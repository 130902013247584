import { Payload, config } from "./config";
type TableRow = { [key: string]: any };

export class Utils {
    public static hide(id: string) {
        this.getElement(id).classList.add("hidden");
    }

    public static show(id: string) {
        this.getElement(id).classList.remove("hidden");
    }

    public static classToggle(classId: string, id: string) {
        const elements = document.querySelectorAll(classId);
        elements.forEach(element => {
            element.classList.toggle(id);
        });
    }

    public static idToggle(id: string, name: string) {
        this.getElement(id).classList.toggle(name);
    }

    public static showhide(id: string, show: boolean) {
        if (show)
            this.show(id);
        else
            this.hide(id);
    }

    public static getElement(id: string, clear: boolean = false): HTMLElement {
        const element = document.getElementById(id);
        if (!element)
            throw new Error("missing essential element: " + id);
        if (clear)
            element.innerHTML = "";
        return element;
    }

    public static populate(id: string, data: Payload) {
        console.log("populate " + id, data);
        const element = this.getElement(id);
        if (!element)
            return;

        if (element.classList.contains('hidden'))
            element.classList.remove('hidden');

        // Find all input elements within the container
        const inputs = element.querySelectorAll('input');

        inputs.forEach(input => {
            const id = input.id;
            // Check if the data object has a matching property
            if (id && id in data)
                input.value = String(data[id]);
            else
                input.value = "";
        });
    }

    public static getValues(id: string): Payload {
        const form = this.getElement(id);
        if (!form)
            throw new Error("Element not found: " + id);

        if (!form.classList.contains('hidden')) {
            form.classList.add('hidden');
        }

        // Find all input elements within the container
        const elements = form.querySelectorAll('input, select');

        const data: Payload = {};
        elements.forEach(element => {
            const formElement = element as HTMLInputElement | HTMLSelectElement;
            if (formElement.disabled)
                return;

            if (formElement.id) {
                let value: any;
                // Convert to number if input type is 'number'
                if (formElement instanceof HTMLInputElement && formElement.type === 'number') {
                    value = formElement.value ? Number(formElement.value) : '';
                } else {
                    if (formElement.value === "true")
                        value = true;
                    else if(formElement.value === "false")
                        value = false;
                    else
                        value = formElement.value;
                }
                // console.log(formElement.id, config.map[id]?[formElement.id], value);
                const mappedKey = config.map[id]?.[formElement.id];
                if (mappedKey) {
                    if (mappedKey == "supersede") {  // special case - only include if it's visible
                        const div = this.getElement(id + "Supersede");
                        // console.log(div);
                        if (div.classList.contains('hidden'))
                            return;
                    }
                    if (value !== null && value !== undefined && value !== '') {
                        if (!data[mappedKey]) {
                            data[mappedKey] = {}; // Initialize nested object if it doesn't exist
                        }
                        data[mappedKey][formElement.id] = value; // Assign value to the nested object
                    }
                }
                else
                    data[formElement.id] = value; // Assign value to the top-level object
            }
        });

        // custom checks
        switch (id) {
            case 'requestBonusPayment':
                if (!data['lifeEventId'])
                    delete data['lifeEventId'];
                break;
        }

        return data;
    }

    public static validate(id: string): boolean {
        console.log("validate form: " + id);
        const form = this.getElement(id);
        if (!form)
            throw new Error("Element not found: " + id);

        const inputs = form.querySelectorAll('input');
        const selects = form.querySelectorAll('select');
        let isValid = true;
        let errorMessages: string[] = [];
        const data = [];

        inputs.forEach((input) => {
            if (input.disabled)
                return;

            data[input.name] = input.value;            // Validate required
            if (input.required && !input.value) {
                errorMessages.push(`The field "${input.name}" is required.`);
                isValid = false;
                return; // skip pattern check
            }

            // Validate pattern
            const pattern = input.getAttribute('pattern');
            if (pattern) {
                const regex = new RegExp(pattern);
                if (!regex.test(input.value)) {
                    errorMessages.push(`The field "${input.name}" does not match the required pattern.`);
                    isValid = false;
                }
            }
        });

        selects.forEach((select) => {
            data[select.name] = select.value;
        });


        if (!isValid) {
            console.log("finished validation failed");
            // alert(`Please fix the following errors:\n\n${errorMessages.join('\n')}`);
            this.showError("Please fix the following errors:", errorMessages);  // needs dedicated error box
        }
        else {
            if (!this.actionValidate(id, data))
                isValid = false;
        }
        return isValid;
    }

    /**
     * Custom validation according to event id
     * @param id
     * @param data
     */
    private static actionValidate(id, data): boolean {
        // console.log("actionValidate: " + id, data);
        let error = "";
        switch (id) {
            case 'requestBonusPayment':
                if (data['claimReason'] == "Life Event" && ! /^\d{10}$/.test(data['lifeEventId']))
                    error = "Life Event Id must be supplies when claim reason if Life Event";
                break;
        }
        if (error) {
            this.showError("Please fix the following errors:", {error: error});
            return false;
        }
        return true;
    }

    public static showInfo(id: string, data: Payload) {
        console.log(id, data);
        const div = this.getElement('info-content');
        const str = this.getDialogContent(id, data);
        div.innerHTML = str;
        this.show('overlay');
        this.show('info');
    }

    public static showError(id: string, data: Payload) {
        console.log(id, data);
        const div = this.getElement('error-content');
        const str = this.getDialogContent(id, data);
        div.innerHTML = str;
        this.show('error-overlay');
        this.show('error');
    }


    public static showTable(id, data: TableRow[]) {
        console.log(id, data);
        const div = this.getElement('info-content');
        let str = "<h2>" + this.camelToSentence(id) + "</h2>";
        if (data.length === 0) {
            str += "<p>No data</p>";
            div.innerHTML = str;
            this.show('overlay');
            this.show('info');
            return;
        }

        const headers = Object.keys(data[0]);
        str += '<table class="table table-striped"><thead><tr>';
        for (const header of headers) {
            str += `<th>${this.camelToSentence(header)}</th>`;
        }
        str += "</tr></thead><tbody>";

        for (const row of data) {
            str += "<tr>";
            for (const header of headers) {
                const cell = row[header] ?? "";
                str += `<td>${cell}</td>`;
            }
            str += "</tr>";
        }
        str += "</tbody></table>";

        div.innerHTML = str;
        this.show('overlay');
        this.show('info');
    }

    private static getDialogContent(title: string, data: Payload): string {
        let str = "<h2>" + this.camelToSentence(title) + "</h2>";

        str += '<table class="table table-striped">';
        const outputRow = (name: string, data: any) => {
            const id = this.camelToSentence(name);
            let value;
            if (typeof data === 'object') {
                value = "";
                for (const key in data) {
                    if (value)
                        value += "<br />"
                    if (data.hasOwnProperty(key))
                        value += `${key}: ${data[key]}`;
                }
            }
            else
                value = data;
            str += `<tr><td>${id}</td><td>${value}</td></tr>`;
        };

        const outputObject = (obj: any) => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key))
                    outputRow(key, obj[key]);
            }
        };

        if (Array.isArray(data)) {
            data.forEach((item, index) => {
            if (typeof item === 'object' && item !== null) {
                outputObject(item);
            } else {
                outputRow(`${index}`, item);
            }
            });
        } else {
            outputObject(data);
        }

        str += '</table>';
        return str;
    }

    public static setDisabled(id: string, state: boolean) {
        const element = document.getElementById(id) as HTMLInputElement;
        if (element)
            element.disabled = state;
    }

    public static showLoader() {
        this.getElement(config.loader).classList.add("active");
    }

    public static hideLoader() {
        this.getElement(config.loader).classList.remove("active");
    }

    public static camelToSentence(text: string): string {
        return text
            .replace(/([A-Z])/g, ' $1')
            .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1 $2')
            .replace(/^./, str => str.toUpperCase())
            .trim();
    }
}


// Create a custom namespace under `window` and assign `Utils`
(window as any).lisaManagerApp = {
    Utils
};