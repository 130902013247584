import { Grid, h as cellAction, h} from "gridjs";

export type Payload = Record<string, any>;

export const config: Payload = {
    loader: "loader",
    grid_ids: {
        details: "grid",
        event: "event-grid",
        transaction: "transaction-grid",
    },
    grids: {
        details: {
            search: true,
            sort: true,
            columns: [
                {
                    id: "investorId",
                    name: 'Investor Id'
                },
                {
                    id: "accountId",
                    name: "Account Id"
                },
                {
                    id: "firstName",
                    name: 'First Name'
                },
                {
                    id: "lastName",
                    name: 'Last Name'
                },
                {
                    id: "investorNINO",
                    name: 'NINO'
                },
                {
                    id: "dateOfBirth",
                    name: 'DOB'
                },
                {
                    id: "updated",
                    name: 'Updated'
                },
                {
                    id: "created",
                    name: 'Created'
                }

            ],
            className: {
                table: 'lisa-table'
            }
        },
        event: {
            sort: true,
            columns: [
                {
                    name: 'Options',
                    width: '120px', // sets the column width to 150 pixels
                    formatter: (cell, row) => {
                        // Using row.index (or another unique identifier) to ensure unique element IDs.
                        const dropdownId = `life-event-${row.cells[1].data}`;
                        return cellAction('div', { className: 'dropdown' }, [
                            cellAction('button', {
                                className: 'btn btn-secondary dropdown-toggle',
                                type: 'button',
                                id: dropdownId,
                                'data-bs-toggle': 'dropdown',
                                'aria-expanded': 'false'
                            }, 'Actions'),
                            cellAction('ul', {
                                className: 'dropdown-menu',
                            'aria-labelledby': dropdownId
                            },
                            [
                                cellAction('li', {}, cellAction('a', {
                                    className: 'dropdown-item life-event-button',
                                    id: 'btn-requestBonusPayment',
                                    'data-target-id': row.cells[1].data,
                                    href: '#' }, 'Request bonus payment')),
                                cellAction('li', {}, cellAction('a', { className: 'dropdown-item', href: '#' }, 'Supersede')),
                                cellAction('li', {}, cellAction('a', {
                                    className: 'dropdown-item info-button',
                                    id: 'info-event',
                                    'data-target-id': row.cells[1].data,
                                    href: '#' }, 'View details')),
                                cellAction('li', {}, cellAction('a', {
                                    className: 'dropdown-item menu-button',
                                    id: 'get-viewLifeEvent',
                                    'data-target-id': row.cells[1].data,
                                    href: '#' }, 'View HMRC data'))
                            ])
                        ]);
                    }
                },
                {
                    id: 'lifeEventId',
                    name: "Life Event Id"
                },
                {
                    id: "instruction",
                    name: "Event Type"
                },
                {
                    id: "eventDate",
                    name: 'Event Date'
                },
                {
                    id: "message",
                    name: 'Message'
                }
            ],
            className: {
                table: 'lisa-table'
            },
            data: []
        },
        transaction: {
            sort: true,
            columns: [
                {
                    name: 'Options',
                    width: '120px', // sets the column width to 150 pixels
                    formatter: (cell, row) => {
                        // Using row.index (or another unique identifier) to ensure unique element IDs.
                        const dropdownId = `life-event-${row.cells[1].data}`;
                        return cellAction('div', { className: 'dropdown' }, [
                            cellAction('button', {
                                className: 'btn btn-secondary dropdown-toggle',
                                type: 'button',
                                id: dropdownId,
                                'data-bs-toggle': 'dropdown',
                                'aria-expanded': 'false'
                            }, 'Actions'),
                            cellAction('ul', {
                                className: 'dropdown-menu',
                            'aria-labelledby': dropdownId
                            },
                            [
                                cellAction('li', {}, cellAction('a', { className: 'dropdown-item', href: '#' }, 'Supersede')),
                                cellAction('li', {}, cellAction('a', {
                                    className: 'dropdown-item info-button',
                                    id: 'info-transaction',
                                    'data-target-id': row.cells[1].data,
                                    href: '#' }, 'View details')),
                                cellAction('li', {}, cellAction('a', {
                                    className: 'dropdown-item',
                                    id: 'get-viewLifeEvent',
                                    'data-target-id': row.cells[1].data,
                                    href: '#' }, 'View HMRC data'))
                            ])
                        ]);
                    }
                },
                {
                    id: 'transactionId',
                    name: "Transaction Id"
                },
                {
                    id: "instruction",
                    name: "Event Type"
                },
                {
                    id: "reason",
                    name: 'Withdrawal reason'
                },
                {
                    id: "message",
                    name: 'Message'
                },
                {
                    id: "created",
                    name: 'Created'
                }
            ],
            className: {
                table: 'lisa-table'
            },
            data: []
        },
    },


    // always POST to backend
    lisa_api: {
        getListOfAllAvailableEndpoints: "post",
        createLisaInvestor: "post",
        createOrTransferLisaAccount: "post",
        reinstateLisaAccount: "post",
        getAccountDetails: "post",
        closeExistingLisaAccount: "post",
        modifyDateOfFirstSubscriptionOfLisaAccount: "post",
        reportDeathOrTerminalIllness: "post",
        sendAnnualReturnOfInformation: "post",
        requestReleaseOfFundsToBuyProperty: "post",
        requestPropertyPurchaseExtension: "post",
        reportOutcomeOfPropertyPurchase: "post",
        viewLifeEvent: "post",
        reportWithdrawalCharge: "post",
        getDetailsOfWithdrawalCharge: "post",
        requestBonusPayment: "post",
        getDetailsOfBonusRequest: "post",
        getPaymentDetailsForBonusClaimOrWithdrawalCharge: "post",
        getListOfAllPaymentsAndDebtsInDateRange: "post"
    },


    map : {
        createOrTransferLisaAccount: {
            transferredFromAccountId: "transferAccount",
            transferredFromLMRN: "transferAccount",
            transferInDate: "transferAccount"
        },
        requestBonusPayment: {
            htbTransferInForPeriod: "htbTransfer",
            htbTransferTotalYTD: "htbTransfer",
            newSubsForPeriod: "inboundPayments",
            newSubsYTD: "inboundPayments",
            totalSubsForPeriod: "inboundPayments",
            totalSubsYTD: "inboundPayments",
            bonusPaidYTD: "bonuses",
            bonusDueForPeriod: "bonuses",
            totalBonusDueYTD: "bonuses",
            claimReason: "bonuses",
            automaticRecoveryAmount: "supersede",
            originalTransactionId: "supersede",
            originalBonusDueForPeriod: "supersede",
            transactionResult: "supersede",
            reason: "supersede"
        },
        reportWithdrawalCharge: {
            originalTransactionId: "supersede",
            originalWithdrawalChargeAmount: "supersede",
            transactionResult: "supersede",
            reason: "supersede"
        },
        reportOutcomeOfPropertyPurchase: {
            originalLifeEventId: "supersede",
            originalEventDate: "supersede"
        },
        requestPropertyPurchaseExtension: {
            originalLifeEventId: "supersede",
            originalEventDate: "supersede"
        },
        requestReleaseOfFundsToBuyProperty: {
            originalLifeEventId: "supersede",
            originalEventDate: "supersede"
        },
        sendAnnualReturnOfInformation: {
            originalLifeEventId: "supersede",
            originalEventDate: "supersede"
        }
    }
}