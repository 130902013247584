const settings = {
  endpoint: 'https://19y5i1f670.execute-api.eu-west-2.amazonaws.com/prod/lisa'
}

type Payload = Record<string, any>;

export class Api {

    /**
     * Swap hmrc code for access token
     */
    public async authorize(token: string, code: string) {
        const data = await this.call(token, "POST", "authorize", {code: code});
    }

    public async getClients(token: string) {
        const data = await this.call(token, "POST", "get-clients", {});
        if (data.status?.success)
            return data.data;
        return [];
    }

    public async getApi(token: string) {
        const data = await this.call(token, "POST", "status", {});
    }

    /**
     * Get a list of all available endpoints
     */
    public async getListOfAllAvailableEndpoints(token: string, payload: Payload) {
        const data = await this.call(token, 'get', 'getListOfAllAvailableEndpoints', payload);
    }

    /**
     * Create a LISA investor
     */
    public async createLisaInvestor(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'createLisaInvestor', payload);
    }

    /**
     * Create or transfer a LISA account
     */
    public async createOrTransferLisaAccount(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'createOrTransferLisaAccount', payload);
    }

    /**
     * Reinstate a LISA account
     */
    public async reinstateLisaAccount(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'reinstateLisaAccount', payload);
    }

    /**
     * Get account details
     */
    public async getAccountDetails(token: string, payload: Payload) {
        const data = await this.call(token, 'get', 'getAccountDetails', payload);
    }

    /**
     * Close an existing LISA account
     */
    public async closeExistingLisaAccount(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'closeExistingLisaAccount', payload);
    }

    /**
     * Modify date of first subscription of a LISA account
     */
    public async modifyDateOfFirstSubscriptionOfLisaAccount(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'modifyDateOfFirstSubscriptionOfLisaAccount', payload);
    }

    /**
     * Report a death or terminal illness
     */
    public async reportDeathOrTerminalIllness(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'reportDeathOrTerminalIllness', payload);
    }

    /**
     * Send an annual return of information
     */
    public async sendAnnualReturnOfInformation(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'sendAnnualReturnOfInformation', payload);
    }

    /**
     * Request the release of funds to buy a property
     */
    public async requestReleaseOfFundsToBuyProperty(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'requestReleaseOfFundsToBuyProperty', payload);
    }

    /**
     * Request a property purchase extension
     */
    public async requestPropertyPurchaseExtension(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'requestPropertyPurchaseExtension', payload);
    }

    /**
     * Report the outcome of a property purchase
     */
    public async reportOutcomeOfPropertyPurchase(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'reportOutcomeOfPropertyPurchase', payload);
    }

    /**
     * View a life event
     */
    public async viewLifeEvent(token: string, payload: Payload) {
        const data = await this.call(token, 'get', 'viewLifeEvent', payload);
    }

    /**
     * Report a withdrawal charge
     */
    public async reportWithdrawalCharge(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'reportWithdrawalCharge', payload);
    }

    /**
     * Get details of a withdrawal charge
     */
    public async getDetailsOfWithdrawalCharge(token: string, payload: Payload) {
        const data = await this.call(token, 'get', 'getDetailsOfWithdrawalCharge', payload);
    }

    /**
     * Request a bonus payment
     */
    public async requestBonusPayment(token: string, payload: Payload) {
        const data = await this.call(token, 'post', 'requestBonusPayment', payload);
    }

    /**
     * Get details of a bonus request
     */
    public async getDetailsOfBonusRequest(token: string, payload: Payload) {
        const data = await this.call(token, 'get', 'getDetailsOfBonusRequest', payload);
    }

    /**
     * Get the payment details for a bonus claim or withdrawal charge
     */
    public async getPaymentDetailsForBonusClaimOrWithdrawalCharge(token: string, payload: Payload) {
        const data = await this.call(token, 'get', 'getPaymentDetailsForBonusClaimOrWithdrawalCharge', payload);
    }

    /**
     * Get a list of all payments and debts in a date range
     */
    public async getListOfAllPaymentsAndDebtsInDateRange(token: string, payload: Payload) {
        const data = await this.call(token, 'get', 'getListOfAllPaymentsAndDebtsInDateRange', payload);
    }

    private async call(
        token: string,
        method: string = 'POST',
        command: string,
        data?: Record<string, any>,
        query?: Record<string, string>
    ): Promise<Record<string, any>> {
        const body = {
            command: command,
            data: data
        }

        try {
            const url = this.appendQuery(settings.endpoint, query);
            const response = await fetch(
                url,
                {
                    method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: body ? JSON.stringify(body) : undefined, // Include body if provided
                }
            );

            const data: Record<string, any> = await response.json();

            if (response.ok) {
                console.log('API response:', data);
                return data;
            } else {
                console.error(`API error: ${response.status}`, data);
                throw new Error(`HTTP error: ${response.status}`);
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    private appendQuery(url: string, params?: Record<string, string>): string {
        if (!params) return url;
        const queryString = new URLSearchParams(params).toString();
        return `${url}?${queryString}`;
    }
}