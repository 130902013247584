import { Grid } from "gridjs";
import { Api } from './api';

const settings = {
    hmrcSandbox: "https://test-www.tax.service.gov.uk/oauth/authorize",
    hmrcProduction: "https://www.tax.service.gov.uk/oauth/authorize",
    clientId: "GRm2WsyqGlc0jrmuPihwH3qLBsV3",
    redirectUri: 'https://lisamanager.com'
}

export class App {
    private customer: Record<string, any>;
    private clients: Record<string, any>[];
    private container: HTMLDivElement;
    private grid: Grid;
    private cognitoToken: string;
    private api: Api;

    constructor(api: Api, container: HTMLDivElement) {
        this.api = api;
        this.customer = {};
        this.clients = [];
        this.container = container;
    }

    public event(action: string) {
    }

    public async initialize(cognitoToken: string) {
        this.cognitoToken = cognitoToken;
        this.clients = await this.api.getClients(this.cognitoToken);
        this.grid = new Grid({
            search: true,
            sort: true,
            // columns: ['Account Id', 'Investor Id', 'First Name', 'Last Name', 'NINO'],
            data: this.clients
        });
        this.grid.on('rowClick', (row, event) => {
            console.log("Row clicked:", row); // Log the clicked row data
            alert(`You clicked on row with ID: ${row.cells[0].data}`);
        });
        this.grid.render(this.container);
    }

    public hmrcUrl(token: string): string {
        return settings.hmrcSandbox + `?response_type=code&client_id=${settings.clientId}&scope=write:lisa%20read:lisa&state=${token}&redirect_uri=${settings.redirectUri}`
    }

}
